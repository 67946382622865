import React from 'react'
import { ThemeProvider as AuthThemeProvider } from '@emotion/react'
import PropTypes, { InferProps } from 'prop-types'
import { ThemeProvider, useTheme } from '@wicadu/arepa/ui'

const propTypes = {
  children: PropTypes.node.isRequired,
}

type Props = InferProps<typeof propTypes>

function Theme({ children }: Props) {
  const theme = useTheme()

  return <AuthThemeProvider theme={theme}>{children}</AuthThemeProvider>
}

Theme.propTypes = propTypes

function WrapperThemeProvider({ children }: Props) {
  return (
    <ThemeProvider mode='LIGHT'>
      <Theme>{children}</Theme>
    </ThemeProvider>
  )
}

WrapperThemeProvider.propTypes = propTypes

export default WrapperThemeProvider
