import React from 'react'
import { Spin } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

function LoadingTemplate () {

  return (
    <Container>
      <Spin color='white' />
    </Container>
  )
}

const Container = styled.div`
  background-color: #056cf2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LoadingTemplate
