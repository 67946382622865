import React from 'react'
import Auth from '@HOCs/AuthProvider'
import Main from '@HOCs/MainProvider'
import Theme from '@HOCs/ThemeProvider'

export const wrapRootElement = ({ element }) => (
  <Theme>
    <Auth>
      <Main>
        {element}
      </Main>
    </Auth>
  </Theme>
)
