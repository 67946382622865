import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes, { InferProps } from 'prop-types'

import { useFetch } from '@wicadu/arepa/hooks'

import useAuth from '@hooks/useAuth'
import LoadingContainer from '@containers/Loading'
import isBrowser from '@utils/isBrowser'

const propTypes = {
  children: PropTypes.node.isRequired
}

type Props = InferProps<typeof propTypes>

function MainProvider ({ children }: Props) {
  const {
    appLoading,
    whoAmILoading,
    whoAmIIsLoading,
    restoredAccessToken,
    accessToken,
    restoredProfile,
    restoreUser,
    restoreUserFailed,
    tokenIsNotVerified
  } = useAuth()

  const skip = useMemo((): boolean =>
    (!restoredAccessToken || !accessToken || restoredProfile)
  , [
    restoredAccessToken,
    accessToken,
    restoredProfile
  ])
  
  const onCompleted = useCallback(({
    whoAmI
  }) => {
    restoreUser(whoAmI)
  }, [
    restoreUser
  ])

  const onError = useCallback((err: Error) => {
    if (err.message.match('Token isn\'t verified'))
      return tokenIsNotVerified()

    restoreUserFailed()
  }, [
    tokenIsNotVerified,
    restoreUserFailed
  ])

  const {
    loading
  } = useFetch(`${process.env.OAUTH_SERVER_HOST}/api/users/who-am-i`, {
    fetchOptions: {
      headers: isBrowser() && new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      })
    },
    onCompleted,
    onError,
    skip
  })

  useEffect(() => {
    whoAmIIsLoading(loading)
  }, [
    loading
  ])

  return (appLoading || whoAmILoading) ? <LoadingContainer /> : children
}

export default MainProvider
