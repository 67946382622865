import React, { Fragment } from 'react'
import LoadingTemplate from '@components/templates/Loading'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
      }
    }
  }
`

function LoadingContainer () {
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate
  } = site?.siteMetadata

  return (
    <Fragment>
      <Helmet title={defaultTitle} titleTemplate={titleTemplate} />

      <LoadingTemplate />
    </Fragment>
  )
}

export default LoadingContainer
