import { createContext, useContext } from 'react'
import { User } from '@ts-types/User'

export type TAuthState = {
  isLoggedIn: boolean
  accessToken: string
  restoredAccessToken: boolean
  restoredProfile: boolean
  profile: User
  isVerifiedToken: boolean
  whoAmILoading: boolean
  redirectTo: string
  appLoading: boolean
}

type TAuthUtils = {
  logIn: (token: string) => Promise<void>
  setVerifiedAccessToken: (token: string) => Promise<void>
  signOut: () => Promise<void>
  restoreToken: (accessToken: string) => Promise<void>
  restoreUser: (profile: User) => Promise<void>
  restoreUserFailed: () => Promise<void>
  tokenIsNotVerified: () => Promise<void>
  whoAmIIsLoading: (loading: boolean) => void
  appIsLoading: () => void
}

type TAuthAction =
  | { type: 'RESTORE_USER', payload: { profile: TAuthState['profile'] } }
  | { type: 'RESTORE_USER_FAILED' }
  | { type: 'RESTORE_TOKEN', payload: { accessToken: TAuthState['accessToken'] } }
  | { type: 'LOG_IN', payload: { accessToken?: string } }
  | { type: 'SET_VERIFIED_ACCESS_TOKEN', payload: { accessToken: string } }
  | { type: 'LOG_OUT' }
  | { type: 'TOKEN_IS_NOT_VERIFIED' }
  | { type: 'WHO_AM_I_IS_LOADING', payload: { loading: boolean } }
  | { type: 'SET_REDIRECT_TO', payload: { redirectTo: string } }
  | { type: 'APP_IS_LOADING' }

export type TAuthContext = TAuthState & TAuthUtils

export const stateDefault: TAuthState = {
  accessToken: '',
  restoredAccessToken: false,
  isLoggedIn: false,
  restoredProfile: false,
  profile: {
    places: [] // remove
  },
  isVerifiedToken: false,
  whoAmILoading: true,
  redirectTo: '',
  appLoading: true
}

export const reducers = (prevState: TAuthState, action: TAuthAction) => {
  switch (action.type) {
    case 'RESTORE_USER':
      return {
        ...prevState,
        isLoggedIn: true,
        isVerifiedToken: true,
        profile: {
          ...prevState.profile,
          ...action.payload.profile
        },
        restoredProfile: true
      }
    case 'RESTORE_USER_FAILED':
      return {
        ...prevState,
        isLoggedIn: false,
        restoredProfile: true
      }
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        restoredAccessToken: true,
        accessToken: action.payload.accessToken,
        appLoading: false
      }
    case 'LOG_IN':
      return {
        ...prevState,
        isLoggedIn: true,
        isVerifiedToken: false,
        accessToken: action.payload.accessToken
      }
    case 'SET_VERIFIED_ACCESS_TOKEN':
      return {
        ...prevState,
        isLoggedIn: true,
        restoredProfile: false,
        isVerifiedToken: true,
        accessToken: action.payload.accessToken
      }
    case 'LOG_OUT':
      return {
        ...prevState,
        isLoggedIn: false,
        restoredProfile: true,
        accessToken: ''
      }
    case 'TOKEN_IS_NOT_VERIFIED':
      return {
        ...prevState,
        isLoggedIn: true,
        isVerifiedToken: false,
        restoredProfile: true
      }
    case 'WHO_AM_I_IS_LOADING':
      return {
        ...prevState,
        whoAmILoading: action.payload.loading
      }
    case 'SET_REDIRECT_TO':
      return {
        ...prevState,
        redirectTo: action.payload.redirectTo
      }
    case 'APP_IS_LOADING':
      return {
        ...prevState,
        appLoading: false
      }
  }
}

const Auth = createContext<any>({})

export const AuthProvider = Auth.Provider

export default (): TAuthContext => useContext(Auth)
