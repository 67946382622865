import * as Sentry from '@sentry/gatsby'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [process.env.OAUTH_SERVER_HOST],
      beforeNavigate: context => {
        return {
          ...context,
          name: `PAGE URL ${location.pathname}`,
        }
      },
    }),
  ],
})
