import isBrowser from '@utils/isBrowser'

export const setItem = (key: string, value: string | number): Record<string, any> => {
  if (!isBrowser() || !key) return {}
  const newValue: Record<string, any> = { [key]: value }
  window.localStorage.setItem(key, JSON.stringify(newValue))
  return newValue
}

export const getItem = (key: string): Record<string, any> => {
  let value: Record<string, any> = {}
  if (!isBrowser() || !key) return value
  const valueStored: string = window.localStorage.getItem(key)
  if (valueStored) value = JSON.parse(valueStored)
  return value
}

export const removeItem = (key: string): void => {
  if (isBrowser() && key) window.localStorage.removeItem(key)
}
